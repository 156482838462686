import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import Section from '../Atoms/Section';
import Container from '../Atoms/Container';
import Row from '../Atoms/Row';
import Column from '../Atoms/Column';
import Heading from '../Atoms/Heading';
import Button from '../Atoms/Button';
import Box from '../Atoms/Box';
import FlexBox from '../Atoms/FlexBox';
import theme from '../../styles/theme';
import CustomLink from '../Atoms/CustomLink';
import { pageUrls } from '../../config/pageSetting';

const heightInMobile = 200;
const heightInDesktop = 400;

const LeftImage = styled(Box)`
  background-image: url('/img/about/subpage-left.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;
const RightImage = styled(Box)`
  background-image: url('/img/about/subpage-right.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

class SubPageList extends Component {
  render() {
    return (
      <Section position="relative" py={0} mt={3} mb={4}>
        <LeftImage
          position="absolute"
          width={['100%', '100%', '50%']}
          height={['50%', '50%', '100%']}
          left={0}
        />
        <RightImage
          position="absolute"
          width={['100%', '100%', '50%']}
          height={['50%', '50%', '100%']}
          right={0}
          top={['50%', '50%', 0]}
        />
        <Container>
          <Row textAlign={['center', 'center', 'center', 'left']}>
            <Column>
              <FlexBox
                height={[heightInMobile, heightInMobile, heightInDesktop]}
                alignItems="flex-start"
                flexDirection="column"
              >
                <Heading h={2} color={theme.color.white} width="100%" fontWeight={700}>
                  <FormattedMessage id="about:subpagelist:1" defaultMessage="Our Business" />
                </Heading>
                <Box width="100%">
                  <CustomLink to={pageUrls.our_business.url}>
                    <Button width={120} primary inverted text="readMore" fontWeight={700} />
                  </CustomLink>
                </Box>
              </FlexBox>
            </Column>
            <Column>
              <FlexBox
                height={[heightInMobile, heightInMobile, heightInDesktop]}
                alignItems="flex-start"
                flexDirection="column"
                pl={[0, 0, 0, 8]}
              >
                <Heading h={2} color={theme.color.white} width="100%" fontWeight={700}>
                  <FormattedMessage id="about:subpagelist:2" defaultMessage="Corporate Profile" />
                </Heading>
                <Box width="100%">
                  <CustomLink to={pageUrls.corporate_profile.url}>
                    <Button width={120} primary inverted text="readMore" fontWeight={700} />
                  </CustomLink>
                </Box>
              </FlexBox>
            </Column>
          </Row>
        </Container>
      </Section>
    );
  }
}

export default SubPageList;
