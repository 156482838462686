import React from 'react';
import { Link, graphql } from 'gatsby';

import { FormattedMessage } from 'react-intl';
import { PageHelmet } from '../components/PageHelmet';
import LeftTextRightImage from '../components/SectionBlocks/LeftTextRightImage';
import PageHeader from '../components/PageHeader';
import HighlightListWithIcons from '../components/SectionBlocks/HighlightListWithIcons';
import TopTextBottomImage from '../components/SectionBlocks/TopTextBottomImage';
import TeamList from '../components/About/TeamList';
import SubPageList from '../components/About/SubPageList';

// ********************************* UI Template (Shared with CMS) *********************************
export const AboutPageTemplate = ({
  title,
  description,
  intro,
  bgImage,
  highlights,
  vision,
  team,
}) => (
  <div>
    <PageHelmet title={title} description={description} />
    <PageHeader
      title={<FormattedMessage id="about:title" defaultMessage="Primech Holdings" />}
      subtitle={<FormattedMessage id="about:subtitle" defaultMessage="About" />}
      bgImage={bgImage}
    />
    <LeftTextRightImage {...intro} />
    <SubPageList />
    <HighlightListWithIcons {...highlights} />
    <TopTextBottomImage {...vision} />
  </div>
);

// ********************************* Render page *********************************
const AboutPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return <AboutPageTemplate {...frontmatter} />;
};

export default AboutPage;

// ********************************* Data graphql *********************************
export const pageQuery = graphql`
  query AboutPageWithId($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        description
        bgImage {
          alt
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        intro {
          title
          desc
          img {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        highlights {
          list {
            label
            desc
            url
            number
          }
        }
        vision {
          title
          desc
          img {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 1024, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
