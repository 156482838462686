import React, { Component } from 'react';
import Section from '../Atoms/Section';
import Container from '../Atoms/Container';
import Column from '../Atoms/Column';
import PreviewCompatibleImage from '../Atoms/PreviewCompatibleImage';
import Heading from '../Atoms/Heading';
import Paragraph from '../Atoms/Paragraph';
import Row from '../Atoms/Row';
import Box from '../Atoms/Box';

class TopTextBottomImage extends Component {
  render() {
    const { title, desc, img, link } = this.props;

    const descArr = desc.split(/\r?\n/);

    return (
      <Section>
        <Container>
          <Row>
            <Column col="is-12">
              <Heading>{title}</Heading>
              {descArr &&
                descArr.map(sub => (
                  <>
                    {!sub && <Paragraph />}
                    {sub && sub !== '' && <Paragraph pb={0}>{sub}</Paragraph>}
                  </>
                ))}
              <Box>
                <PreviewCompatibleImage imageInfo={img} />
              </Box>
            </Column>
          </Row>
        </Container>
      </Section>
    );
  }
}

export default TopTextBottomImage;
